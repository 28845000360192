import React, { useEffect, useState } from 'react';
import { getConcursos } from '../../services/concursos';
import {
  Input,
  notification,
  Table } from 'antd';
  import { SearchOutlined } from '@ant-design/icons';
  

const Concursos = () => {
  // state para llenar la data de la tabla
  const [dataConcursos, setDataConcursos] = useState([]);
  // state para realizar las busquedas
  const [page, setPage] = useState({
    currentPage: 1,
    year: '',
    title: '',
    status: '',
    pageOffset: '',
    pageSize: '',
  })

  useEffect(() => {
    getDataConcursos();
  }, [page]);

  const getDataConcursos = async () => {
    try {
      const concursos = await getConcursos(
        page.year,
        page.title,
        page.status,
        page.pageOffset,
        page.pageSize
      );
      concursos.data?.data?.forEach((e) => {
        e.status =( e.status === 1 ? "Activo" : "Inactivo")
        return e
      }
      );
      setDataConcursos(concursos.data);
    } catch (error) {
      console.log(error.response);
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
        duration: 0,
      });
    }
  };

  const columns = [
    {
      title: 'Año',
      dataIndex: 'year',
      key: 'year',
      filterDropdown: ( {setSelectedKeys, selectedKeys, confirm} ) => {
        return <Input
          autoFocus
          placeholder='Buscar'
          type='number'
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      },
      filterIcon: () => {
        return <SearchOutlined></SearchOutlined>
      },
      onFilter: (value, record) => {
        return record.year.includes(value);
      },
    },
    {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ( {setSelectedKeys, selectedKeys, confirm} ) => {
        return <Input
          autoFocus
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        ></Input>
      },
      filterIcon: () => {
        return <SearchOutlined></SearchOutlined>
      },
      onFilter: (value, record) => {
        return record.title.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) =>{
        return <a href={process.env.REACT_APP_PROMESSA_BASE_URL + '/concurso/' + record.year + '/' + record.name}>{text}</a>
        
      }
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Activo',
          value: 'Activo',
        },
        {
          text: 'Inactivo',
          value: 'Inactivo',
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
  ];

  return (
      <Table
        dataSource={dataConcursos.data}
        columns={columns}
        pagination={{
          defaultCurrent: 1,
          defaultPageSize: 20,
          showLessItems: true,
          onChange: (record) => {
            setPage({...page, currentPage: record});
            getDataConcursos();
          }
        }}
      />
  );
};

export default Concursos;
